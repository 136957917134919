@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
/*@import '../../node_modules/@syncfusion/ej2-notifications/styles/material.css';*/
@import '../../node_modules/@syncfusion/ej2-react-grids/styles/material.css';

button:focus {
  outline: none;
}

:root {
  --sk-color: #1565c0;
  --primary: #3f51b5;

  font-family: 'Montserrat', Verdana, sans-serif;
}